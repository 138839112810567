import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`Welcome back! The Ville is open for classes starting at 6:00am!
 Please check the schedule page for updated class times.  Members will
need to sign up for class ahead of time on the link we emailed you.  For
any questions contact: Daniel\\@crossfittheville.org`}</em></strong></p>
    <p>{`Push Press 4×6 to a 6RM`}</p>
    <p>{`Wt’d Strict Pullups 4×6 to a 6RM`}</p>
    <p>{`then,`}</p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`10-S2OH (115/75)(RX+ 135/95)`}</p>
    <p>{`15-T2B`}</p>
    <p>{`20/15-Calorie Assault Bike `}<em parentName="p">{`(because we know you missed it)`}</em></p>
    <p><em parentName="p">{`*`}{`Please clean and tidy up your lanes after class for the next class.
 Each lane will have it’s own equipment, cleaning supplies and even it’s
own marker for the white board.  If you need something in your lane ask
your coach.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      